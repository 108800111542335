//// Grid settings
//$main-sm-columns:       12;
//$sidebar-sm-columns:    4;
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (eot: "?",
        svg: "#"+ str-replace($name, " ", "_"));

    $formats: (otf: "opentype",
        ttf: "truetype");

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "."+ $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––
// A map of breakpoints.
$breakpoints: (xs: 576px,
sm: 768px,
md: 992px,
lg: 1200px);



@mixin respond-above($breakpoint) {

    @if map-has-key($breakpoints,
    $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (min-width: $breakpoint-value) {
            @content;
        }
    }

    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-below($breakpoint) {

    @if map-has-key($breakpoints,
    $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    }

    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-between($lower,
$upper) {

    @if map-has-key($breakpoints,
    $lower) and map-has-key($breakpoints,
    $upper) {
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }
    }

    @else {
        @if (map-has-key($breakpoints, $lower)==false) {
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        @if (map-has-key($breakpoints, $upper)==false) {
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}


@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

$c-darkblue : #002663;
$c-lightblue : #0085cf;
$c-green : #bed600;
$c-grey : #5d6775;
$c-white: #fffffd;

$var1: #234567;
$var2: #faf;
