$breakpoints: ( xs: 576px,
sm: 768px,
md: 992px,
lg: 1200px);

@mixin respond-above($breakpoint) {
    @if map-has-key($breakpoints,
    $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint); 
        @media (min-width: $breakpoint-value) {
            @content;
        }
    }
    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-below($breakpoint) {
    @if map-has-key($breakpoints,
    $breakpoint) {
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
    }
    @else {
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin respond-between($lower,
$upper) {
    @if map-has-key($breakpoints,
    $lower) and map-has-key($breakpoints,
    $upper) {
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }
    }
    @else {
        @if (map-has-key($breakpoints, $lower)==false) {
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }
        @if (map-has-key($breakpoints, $upper)==false) {
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}


@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}


@mixin IE {
    @media screen and (min-width:0\0) {
        @content;
    }
}


@mixin edge {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

@mixin quickAccess($color) {
    border-bottom: 7px solid $color;
    transition:  border-bottom .15s linear;

    @include respond-above(md) {
        border-bottom: 0px solid $color;
    }
}

@mixin text-contrast($n) {
    $color-brightness: round((red($n)*299)+(green($n)*587)+(blue($n)*114)/1000);
    $light-color: round((red(#ffffff)*299)+(green(#ffffff)*587)+(blue(#ffffff)*114)/1000);

    @if abs($color-brightness) < ($light-color/2) {
        color: white;
    }

    @else {
        color: black;
    }
}
