.contenuDeLaPage {
    margin-bottom: 120px;

    .tarif{
        font-weight: 700;
        text-transform: uppercase;
    }
}

.infoBanner {
    border-radius: 2px;
    overflow: hidden;
    
    margin-bottom: 30px;
    
    .imageWrap, .contentWrap {
        height: 370px;
        
    }
    
    .imageWrap {
 
        
        @include respond-above(md) {
            padding-right: 0;
        }
        figure {
            height: 100%;
            width: 100%;
            
            position: relative;
            
            overflow: hidden;
            border-radius: 2px 0 0 2px;
            /*border-bottom: 3px solid #bed600;*/
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
            figcaption {
                position: absolute;
                top: 0;
                left: 0;
                
                padding:0 16px;
                height: 40px;

                /*background-color: #bed600;*/
                
                font-family: Raleway;
                font-size: 0.8125rem;
                font-weight: 700;
                text-transform: uppercase;
                
                text-align: center;
                line-height: 40px;
            }
        }
    }
    
    .contentWrap {
        min-height: 370px;

        
        @include respond-above(md) {
            padding-left: 0;
        }
        
        .backgroundWrap {
            height: 100%;
            
            padding-top: 30px;
            padding-left: 50px;
            padding-right: 50px;
            
            background-color: #f2f2f2;
            
            border-radius: 0 2px 2px 0;
            /*border-bottom: 3px solid #bed600;*/
            overflow: hidden;
        }
        
        .dateWrap {
            margin-bottom: 10px;
            .date, .month {
                color: #2f2f2f;
                font-weight: 700;
                font-family: Raleway;
                text-transform: uppercase;
                font-size: 2.8125rem;
            }
            
            .date {
                line-height: .9;
            }
            
            .month {
                font-size: 1.625rem;
            }
            .multipleDate {
                display: inline-block;
                
                .firstDate, .secondDate {
                    display: inline-block;
                            
                    margin: 0 13px;
                }
                        
                position: relative;

                &:after {
                    content: '-';

                    position: absolute;
                    top: 50%;
                    right: 50%;

                    transform: translateY(-50%) translateX(50%);
                }
            }
        }
        
        .horaireWrap, .datePublication {
            color: #2f2f2f;
            font-family: "Raleway - Extra Bold";
            font-size: 1rem;
            font-family: Raleway;
            font-weight: 500;
            
            margin-bottom: 21px;
            
            i {
                font-size: 0.75rem;
                vertical-align: middle;
            }
            
            strong {
                font-weight: 700;
            }
        }
        
        .titleWrap {
            h1 {
                padding-top: 24px;
                
                
                margin-bottom: 15px;
                padding-bottom: 0;
                
                @include respond-above(lg) {
                    padding-right: 15px;
                }
                
                line-height: 1.2;
                
                border-bottom: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
        
        .adresse {
            color: #2f2f2f;
            font-family: "Raleway";
            font-size: 0.8125rem;
            font-weight: 600;
            
            i {
                font-size: 0.625rem;
            }
            
            strong {
                font-weight: 700;
            }
        }
    }
}