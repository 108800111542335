.newsletterWrap {
    min-height: 270px;
    background-color: #002663;
    
    padding-top: 38px;
    padding-bottom: 38px;
    
    overflow: hidden;
    
    position: relative;
    
    &:before {
        position: absolute;
        left: 50%;
        bottom: -50%;
        transform: translateX(-50%) translateY(-50%);
        
        content: 'NEWSLETTER';
        opacity: 0.05;
        color: #ffffff;
        font-family: "Raleway";
//        font-size: 306px;
        font-size: 15vw;
        line-height: .7;
        
        font-weight: 900;
        text-transform: uppercase;
    }
    
    h2 {
        color: #ffffff;
        font-family: Raleway;
        font-size: 1.75rem;
        font-weight: 300;
        padding: 0;
        margin: 0;
        
        padding-bottom: 22px;
        margin-bottom: 23px;
        
        position: relative;
        
        z-index: 10;
        
        @include respond-above(md) {
            font-size: 2.25rem;
        }
        
        span {
            all: unset;
            z-index: 10;
            position: relative;

            background-color: transparent;
            padding: 0;
        }
        
        &:after {
            position: absolute;
            
            top: 30%;
            left: -23px;
            transform: translateY(-50%);
            
            display: block;
            content: '';
            background-image: url('../images/newsletter.svg');
            background-color: transparent;
            background-size: 47px 47px;
            height: 47px;
            width: 47px;
            
            z-index: 0;
        }
        
        &:before {
            position: absolute;
            bottom: 0;
            top: initial;
            
            display: block;
            z-index: 0;
            
            content: "";
            width: 100%;
            height: 1px;
            background-color: #ffffff;
            opacity: 0.2;
        }
    }
    
    .inputWrap {
        margin-bottom: 30px;
        
        @include clearfix;
        input[type="email"], input[type="submit"] {
            @include respond-above(sm) {
                float: left;
            }
        }
        
        input[type="email"] {
            width: 100%;
            height: 45px;
            border-radius: 2px 0 0 2px;
            background-color: #ffffff;
            border: none;
            outline: 0;
            
            padding-left: 20px;
            
            @include respond-above(sm) {
                width: calc(100% - 150px);
            }
            
            &::-webkit-input-placeholder {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 0.9375rem;
                font-weight: 500;
            }

            &::-moz-placeholder {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 0.9375rem;
                font-weight: 500;
            }

            &:-ms-input-placeholder {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 0.9375rem;
                font-weight: 500;
            }

            &:-moz-placeholder {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 0.9375rem;
                font-weight: 500;
            }
        }
        
        input[type="submit"] {
            width: 100%;
            height: 45px;
            border-radius: 0 2px 2px 0;
            background-color: #0085cf;
            
            border: none;
            
            color: #fffffd;
            font-family: "Raleway";
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;
            
            @include respond-above(sm) {
                width: 150px;
            }
        }
    }
    
    .description {
        margin: 0;
        
        text-align: center;
        color: rgba(255, 255, 253, 0.7);
        font-family: Raleway;
        font-size: 0.75rem;
        font-weight: 500;
    }
}

footer {
    min-height: 180px;
    padding-top: 38px;
    padding-bottom: 38px;
    background-color: #5d6775;
    
    @include respond-above(md) {
        padding-bottom: 0;
    }
    
    .logoWrap {
        a {
            img {
                height: 100px;
                
                display: block;
                margin: 0 auto 30px;
                
                @include respond-above(md) {
                    margin: auto;
                }
            }
        }
    }
    
    .contactWrap {
        text-align: center;
        
        margin-bottom: 40px;
        
        @include respond-above(md) {
            text-align: left;
        }
        
        .nom {
            color: #fffffd;
            font-family: Raleway;
            font-size: 1rem;
            font-weight: 700;
            
            margin-bottom: 22px;
        }
        
        .adresse {
            color: #fffffd;
            font-family: Raleway;
            font-size: 0.9375rem;
            font-weight: 500;
            
            padding-left: 18px;
            
            margin-bottom: 23px;
            
            position: relative;
            
            &:before {
                content: '\ed42';
                font-family: icofont;
                
                @include respond-above(md) {
                    position: absolute;
                    left: 0;
                }
            }
        }
        
        .numero {
            position: relative;
            
            padding-left: 18px;
            
            &:before {
                content: '\efbb';
                font-family: icofont;
                
                color: #fffffd;
                
                @include respond-above(md) {
                    position: absolute;
                    left: 0;
                }
            }
            a {
                color: #fffffd;
                font-family: Raleway;
                font-size: 0.9375rem;
                font-weight: 500;
            }
        }
    }
    
    .nav {
        margin-bottom: 25px;
        ul {
            padding: 0;
            margin: 0;
            
            text-align: center;
            
            @include respond-above(md) {
                text-align: left;
            }
            
            li {
                list-style: none;
                
                margin-bottom: 25px;
                
                
                @include respond-above(md) {
                    display: inline-block;
                    
                    margin-right: 40px;
                    margin-bottom: 0;
                }
                
                a {
                    color: #fffffd;
                    font-family: Raleway;
                    font-size: 1rem;
                    font-weight: 700;
                }
            }
        }
    }
    
    .socials {
        text-align: center;
        margin-bottom: 25px;
        
        @include respond-above(md) {
            text-align: left;
        }
        
        span, ul {
            display: inline-block;
            vertical-align: top;
            
        }
        span {
            color: #fffffd;
            font-family: Raleway;
            font-size: 1rem;
            font-weight: 700;
            
            margin-right: 25px;
        }
        
        ul {
            padding: 0;
            margin: 0;
            
            li {
                display: inline-block;
                margin-right: 25px;
                
                a {
                    i {
                        color: white;
                    }
                }
            }
        }
    }
    .logos-off{
        margin-bottom: 15px;
        text-align: center;
        @include respond-above(md) {
            text-align: left;
        }
        img{
            height: 65px;
            width: auto;
            &:last-of-type{
                margin-left: 10px;
            }
        }
    }
}