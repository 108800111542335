table:not(.ui-datepicker-calendar) {
    display: block;
    
    width: 100%;
    
    border-bottom: 3px solid #bed600;
    
    margin-bottom: 35px;
   
    @include respond-above(md) {
        display: table;
    }
    
    tbody {
        display: table;
        width: 100%;
        tr {
            display: block;
            padding: 14px 0 7px;

            background: #f2f2f2;

            @include respond-above(md) {
                display: table-row;
            }

            &:nth-of-type(even) {
                background: #ffffff;
            }

            &.first-of-type {
                padding: 0;

                color: #2f2f2f;
                font-family: Raleway;
                font-size: 1.0625rem;
                font-weight: 900;

                .cell {
                    display: none;

                    @include respond-above(md) {
                        display: table-cell;

                        min-height: 60px;
                        padding: 20px 12px;

                        height: initial;
                    }
                }
            }

            td {
                display: block;

                border-top: 0 !important;

                padding: 2px 16px;
                margin-bottom: 10px;

                color: #2f2f2f;
                font-family: Raleway;
                font-size: 1.0625rem;
                font-weight: 700;



                &:last-of-type {
                    border-right: 0;
                }

                &:before {
                    margin-bottom: 3px;
                    content: attr(data-title);
                    min-width: 98px;
                    font-size: 0.625rem;
                    line-height: 2.4;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #969696;
                    display: block;
                }

                @include respond-above(md) {
                    display: table-cell;

                    text-align: center;

                    min-height: 60px;
                    padding: 20px 12px;

                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    &:before {
                        display: none;
                    }
                }
            }

            @include respond-above(md) {
                td:first-child:nth-last-child(1) {
                    width: 100%;
                }

                $class-slug: cell !default;

                @for $i from 2 through 10 {
                    .#{$class-slug}:first-child:nth-last-child(#{$i}),
                    .#{$class-slug}:first-child:nth-last-child(#{$i}) ~ .#{$class-slug} {
                        width: 100% / $i;
                    }
                }
            }
        }
    }

}

h1 {
    color: #2f2f2f;
    font-family: Raleway;
    font-size: 2.25rem;
    font-weight: 300;
    
    margin-top: 0;
    margin-bottom: 30px;
    padding-bottom: 20px;
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

h2 {
    display: block;
    
    margin-bottom: 40px;
    
    color: $c-darkblue;
    
    font-family: "Raleway";
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    
    position: relative;
    
    span {
        display: inline-block;
        
        padding-left: 22px;
        padding-right: 22px;
        
        background-color: #fff;
        
        z-index: 1;
        
        position: relative;
    }
    
    &:after {
        content: '';
        
        position: absolute;
        
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        
        width: 12px;
        height: 18px;
        background-color: $c-darkblue;
        
        z-index: 1;
    }
    
    &:before {
        content: '';
        
        z-index: -1;
        
        position: absolute;
        
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        
        height: 1px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.1);
    }
}

figure {
    margin: 0 auto 40px;
    
    max-width: 100%;
    
    &.banner {
        img {
            border-radius: 3px;
        }
    }
    
    img {
        display: block;
        margin: 0 auto;
        
        max-width: 100%;
        
        figcaption {
            p {
                
            }
        }
    }
}

p {
    color: #2f2f2f;
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 500;
    
    margin-bottom: 30px;
    
    line-height: 24px;
    word-break: keep-all;
}

.deuxCol {
    .colLeft, .colRight {
        a {
            &.link {
                display: block;
                
                margin-bottom: 35px;
                
                color: $c-darkblue;
                font-family: "Raleway";
                font-weight: 700;
                text-decoration: underline;
                
                position: relative;
                
                padding-left: 20px;
                font-size: 1rem;
                
                &:before {
                    position: absolute;
                    
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    
                    font-family: icofont;
                }
            }
            &.externalLink {
                &:before {
                    content: '';
                    display:block;
                    height:15px;
                    width:15px;
                    background-size: 15px 15px;
                    background-image: url(../images/facebook.svg);
                    background-repeat: no-repeat;
                }
            }
                            
            &.fileLink {
                &:before {
                    content: '\ef08';
                    font-family: icofont;
                    color: $c-darkblue;
                }
            }
                            
            &.internalLink {
                &:before {
                    content: '\ef71';
                    font-family: icofont;
                    color: $c-darkblue;
                }
            }
        }     
    }
    
    .colLeft {
        
    }
    
    .colRight {
        
    }
}

.galerie{
    margin-bottom: 35px;
}

a {
        &.link {
            display: block;
            
            margin-bottom: 35px;
            
            color: $c-darkblue;
            font-family: "Raleway";
            font-weight: 700;
            text-decoration: underline;
            
            position: relative;
            
            padding-left: 20px;
            font-size: 1rem;
            
            &:before {
                position: absolute;
                
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                
                font-family: icofont;
            }
        }
    &.externalLink {
        &:before {
            content: '';
            display:block;
            height:15px;
            width:15px;
            background-size: 15px 15px;
            background-image: url(../images/facebook.svg);
            background-repeat: no-repeat;
        }
    }
                            
    &.fileLink {
        &:before {
            content: '\ef08';
            font-family: icofont;
            color: $c-darkblue;
        }
    }
                    
    &.internalLink {
        &:before {
            content: '\ef71';
            font-family: icofont;
            color: $c-darkblue;
        }
    }
} 

ul {
    padding: 0;
        
    li {
        list-style-type: none;
        color: #2f2f2f;
        font-family: Raleway;
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
    }
}

.contenuBloc{
    ul {        
        li {
            &:before {
                content: '\ea69';
                font-family: icofont;
            }
        }
    }
    
}

.breadcrumbWrap {
    margin-bottom: 25px;
    span{
        font-family: Raleway;
        font-size: 0.875rem;
        font-weight: 500;
        a{
            color: #2f2f2f;
        }
    }
    .breadcrumb_last {
        font-style: italic;
    }
    /*ul {
        li {
            display: inline-block;
            
            a {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 0.875rem;
                font-weight: 500;
            }
            
            &:before {
                display: none;
            }
            
            &:after {
                content: '>';
                
                margin-left: 3px;
            }
            
            &:last-of-type {
                font-style: italic;
                
                &:after {
                    display: none;
                }
            }
        }
    }*/
}

aside {
    .blocInfoPratique {
        /*height: 380px;*/
        height: auto;
        
        margin-bottom: 30px;
        padding-top: 40px;
        padding-left: 37px;
        padding-right: 50px;
        padding-bottom: 40px;
        
        border-radius: 2px;
        background-color: $c-green;
        
        position: relative;
        overflow: hidden;
        
        h2 {
            color: #2f2f2f;
            font-family: "Raleway";
            font-size: 1.25rem;
            font-weight: 900;
            text-transform: uppercase;
            margin: 0;
            
            &:before {
                display: none;
            }
            
            &:after {
                display: none;
            }
        }
        
        h3 {
            color: #2f2f2f;
            font-family: Raleway;
            font-size: 1rem;
            font-weight: 700;
        }


        .adresse{
            position: relative;
            padding-left: 20px;
            &:before {
                position:absolute;
                top: 3px;
                left: 0px;
                content: '\ef79';
                font-family: icofont;
                color: #2f2f2f;
            }
            p {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 1rem;
                font-weight: 500;  
            }
        }

        .tel{
            position: relative;
            padding-left: 20px;
            &:before {
                position:absolute;
                top: 3px;
                left: 0px;
                content: '\efbb';
                font-family: icofont;
                color: #2f2f2f;
            }
            a{
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 1rem;
                font-weight: 700;
                word-break: break-all;

                text-decoration: none;
            }
        }
        
        .mail {
            position: relative;
            padding-left: 20px;
            &:before {
                position:absolute;
                top: 3px;
                left: 0px;
                content: '\ef14';
                font-family: icofont;
                color: #2f2f2f;
            }
            a {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 1rem;
                font-weight: 700;
                word-break: break-all;
                
                text-decoration: underline;
            }
        }
        
        .social {
            position: relative;
            padding-left: 20px;
            margin-top: 20px;
            &:before {
                position:absolute;
                top: 5px;
                left: 0px;
                content: '';
                display:block;
                height:14px;
                width:14px;
                background-size: 14px 14px;
                background-image: url(../images/facebook-grey.svg);
                background-repeat: no-repeat;
            }
            a {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 1rem;
                font-weight: 500;  
                margin-top: 20px;
                word-break: break-all;
            }
        }
        .ouverture{ 
            p{
                margin-bottom: 0px;
            }
        }
        
        .deco {
            position: absolute;
            top: -5px;
            left: -5px;
            
            transition: all .15s linear;
            
            svg {
                fill: #2f2f2f;
                opacity: .15;
                
                height: 75px;
                width: 75px;
            }
        }
        
        &:hover {
            .deco {
                top: -10px;
                left: -10px;
                
                transition: all .15s linear;
            }
        }
    }
    
    .agendaLink {
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        
        background-color: $c-lightblue;
        
        transition: all .15s linear;
        
        a {
            display: block;
            
            z-index: 10;
            position: relative;
            
            padding-top: 20px;
            padding-left: 38px;
            
            height: 95px;
            border-radius: 2px;

            color: #ffffff;
            font-family: "Raleway";
            font-size: 1.25rem;
            font-weight: 900;
            text-transform: uppercase;
            text-decoration: none;
        }
        
        .deco {
            position: absolute;
            top: -5px;
            left: -5px;
            
            transition: all .15s linear;
            
            svg {
                fill: #2f2f2f;
                opacity: .15;
                
                height: 75px;
                width: 75px;
            }
        }
        
        &:hover {
            background-color: lighten($c-lightblue, 5%);
            transition: all .15s linear;
            
            .deco {
                top: -10px;
                left: -10px;
                
                transition: all .15s linear;
            }
        }
    }
    
    .lienAnnexes {
        border-radius: 2px;
        background-color: $c-darkblue;
        
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 37px;
        
        position: relative;
        overflow: hidden;
        
        h2 {
            color: #ffffff;
            font-family: "Raleway";
            font-size: 1.25rem;
            font-weight: 900;
            text-transform: uppercase;
            
            margin-top: 0;
            margin-bottom: 20px;
            
            &:before, &:after {
                display: none;
            }
        }
        
        ul {
            li {
                line-height: 28px;
                
                &:before {
                    content: '\eaa0';
                    font-family: icofont;
                    color: #fff;
                }
                a {
                    color: #ffffff;
                    font-family: "Raleway";
                    font-size: 1.0625rem;
                    font-weight: 500;
                }
            }
        }
        
        .deco {
            position: absolute;
            top: -30px;
            left: -5px;
            
            transition: all .15s linear;
            
            font-size: 4.6875rem;

            i {
                color: white;
                opacity: .15;
                font-size: 4.6875rem;
            }
        }
        
        &:hover {
            .deco {
                top: -40px;
                left: -10px;
                
                transition: all .15s linear;

            }
        }
    }
}

/* Customize the label (the container) */
.customCheckbox {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #2f2f2f;
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: auto;
        width: 100%;
        z-index: 1;
        margin-left: -22px; 
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 13px;
        height: 13px;
        border-radius: 2px;
        background-color: #fffffd; 

        &:after {
          content: "\eed8";
          font-family: icofont;
          position: absolute;
          display: none;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%); 
        }
    }

    input:checked ~ .checkmark:after {
        display: block; 
    }
}

input:focus{
    outline: 0;
}

$map: (
    socioculturel   : #bed600,
    musique         : #ffa400,
    bibliotheque    : #002663,
    spectacle       : #ffd43d,
    numerique       : #e6393c,
    servicesJeunes  : #00a6a6,
    servicesSeniors : #0085cf,
    default : #5d6775
);

@each $class,
$color in $map {
    .categorie.#{$class} {
        background-color: $color;

        @include text-contrast($color); 
    }
}

@each $class,
$color in $map {
    .menuLink.#{$class} {
        
        border-left: 10px solid $color;
        
        @include respond-above(md) {
            border-left: 0;
        }
        
        & > a:after {
            background-color: $color;
        }
        
        & > ul {
            & > li {
                & > a {
                    &:hover {
                        border-left: 10px solid $color;
                        transition: all .15s linear;
                    }
                }
            }
        }
    }

    .infoBanner .contentWrap .backgroundWrap.#{$class} {
        border-bottom: 3px solid $color;
    }

    .infoBanner .imageWrap figure.#{$class}{
        border-bottom: 3px solid $color;
    }
}

@each $class,
$color in $map{
    .hidden-links{
        .menuLink.#{$class} {
        
            border-left: 10px solid $color;
            
            & > ul {
                & > li {
                    & > a {
                        &:hover {
                            border-left: 10px solid $color;
                            transition: all .15s linear;
                        }
                    }
                }
            }
        }
    }
}

@each $class,
$color in $map {
    .quickaccess {
        a {
            figure.#{$class} {
                border-bottom: 5px solid $color;
            }
            
            &:hover {
                figure.#{$class} {
                    box-shadow: 0 1px 10px 4px rgba(0, 0, 0, 0.04);
                    figcaption {
                        color: $color;
                        background-color: lighten($color, 35%);
//                        background-color: rgba($color, .4);
                        
                        span {
                            &:after, &:before {
                                background-color: $color;
                            }
                        }
                    }
                }
            }
        }
    }
}

#aside_right_toolbar {
    position: fixed;
    top: 250px;

    z-index: 15;

    height: 60px;
    right: 0;

    @include respond-above(md) {
        top: 350px;
    }

    transition: all .15s linear;

    .right_aside {
        display: none;

        @include respond-above(md) {
          display: block;
        }
    }

    .zeno_font_resizer{
        margin-bottom: 0px;
    }

    .language{
        ul{
            margin-bottom: 0px;
        }
        a{
            font-size: 1rem!important;
            border-top: 1px solid rgba(0, 0, 0, 0.1)!important;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1)!important;
        }

    }

    a, button {
        float: none;
        display: block;
        height: 45px;
        width: 45px;

        background-color: #fff;
        background-image: unset;
        outline: 0;

        line-height: 45px;

        text-align: center;

        color: #2f2f2f;
        font-family: "Raleway";
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;

        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 0;
        border-top: 0;

        text-decoration: none;

        position: relative;

        @include respond-above(md) {
            height: 60px;
            width: 60px;

            line-height: 60px;

            &.close_toolbar, &.extend_toolbar {
                display: none;
            }
        }

        &.zeno_font_resizer_reset{
            display: none;
        }

        &:hover, &:active, &:focus {
            text-decoration: none;
            background-color: $c-darkblue;
            color: #fff;

        }

        &.extend_toolbar {
            background-color: $c-darkblue;
            color: #fff;
        }

        &.extend_toolbar:hover {
            background-color: #fff;
            color: $c-darkblue;
        }

        &.close_toolbar {
            display: none;
            background-color: $c-darkblue;
            color: #fff;
        }

        &.close_toolbar:hover {
            background-color: #fff;
            color: $c-darkblue;
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }

    a {
        position: relative;
        transition: all .15s linear;

        @include respond-above(md) {
            left: 0;
        }
    }

    @include clearfix;

    @include respond-above(md) {
        height: initial;

        -webkit-box-shadow: 0 0 10px rgba(#000, 0.15);
        -moz-box-shadow: 0 0 10px rgba(#000, 0.15);
        -ms-box-shadow: 0 0 10px rgba(#000, 0.15);
        box-shadow: 0 0 10px rgba(#000, 0.15);

        button {
            display: none;
        }
    }

    &.active {
        -webkit-box-shadow: 0 0 10px rgba(#000, 0.15);
        -moz-box-shadow: 0 0 10px rgba(#000, 0.15);
        -ms-box-shadow: 0 0 10px rgba(#000, 0.15);
        box-shadow: 0 0 10px rgba(#000, 0.15);


        transition: all .15s linear;

        a {
            left: 0;
            transition: all .15s linear;
        }
    }
}

html:not(.translated-ltr) .language{

    li:first-of-type{
        position:relative;
        a{
            padding-right: 5px;
        }
        &:hover:after{
            color:white;
        }
    }

    li:first-of-type:after{
        content:"\eab2";
        font-family: 'icofont';
        position: absolute;
        top: 13px;
        right: 0px;

        @include respond-above(sm){
            top: 22px;
            right: 5px;
        }
    }

    li:last-of-type{
        display:none;
        transition: all .15s linear;
    }

    ul:hover{
        li{
            display:block;
            transition: all .15s linear;
        }
    }

}

html.translated-ltr .language{

    li:first-of-type{
        position:relative;
        a{
            padding-right: 5px;
        }
        &:hover:after{
            color:white;
        }
    }

    li:last-of-type{
        position:relative;
        a{
            padding-right: 5px;
        }
    }

    li:last-of-type:after{
        content:"\eab2";
        font-family: 'icofont';
        position: absolute;
        top: 13px;
        right: 0px;

        @include respond-above(sm){
            top: 22px;
            right: 5px;
        }
    }

    li:first-of-type{
        display:none;
        transition: all .15s linear;
    }

    ul:hover{
        li{
            display:block;
            transition: all .15s linear;
        }
        li:first-of-type:after{
            content:"\eab2";
            font-family: 'icofont';
            position: absolute;
            top: 13px;
            right: 0px;

            @include respond-above(sm){
                top: 22px;
                right: 5px;
            }
        }
        li:last-of-type:after{
            display: none;
        }
    }
}
