.sliderWrap {
    margin-bottom: 70px;
    
    * {
        outline: 0;
    }
    
    .headerSlider {
        padding: 0;
        .slick-arrow {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 3px solid #ffffff;
            background-color: #ffffff;
            
            outline: 0;
            
            padding: 0;
            
            line-height: 25px;
            text-align: center;
            
            transition: all .15s linear;
            
            @include respond-above(md) {
                width: 45px;
                height: 45px;
            }
            
            i {
                font-size: 0.75rem;
                
                display: block;
                
                @include respond-above(md) {
                    font-size: 1.25rem;
                }
            }
            
            &:hover {
                transform: scale(1.05);
                transition: all .15s linear;
            }
        }
        
        .slick-dots {
            list-style-type: none;
            
            position: absolute;
            bottom: 30px;
            left: 50%;
            transform: translateY(-50%);
            
            height: 15px;
            
            padding: 0;
            
            @include respond-above(md) {
                bottom: 55px;
            }
            li {
                margin: 0 0.25rem;
                
                display: inline-block;
            }
            button {
                display: block;
                
                width: 13px;
                height: 13px;
                border: 2px solid #ffffff;
                background: transparent;
                
                outline: 0;
                
                padding: 0;
                border-radius: 100%;
                text-indent: -9999px;
            }
            
            li:not(.slick-active) button:hover {
                background-color: rgb(219, 219, 219);
                border: 2px solid rgb(219, 219, 219);
            }
            li.slick-active button {
                background-color: #ffffff;
            }
        }
        
        figure {
            position: relative;
            
            img {
                width: 100%;
                
                height: 260px;
                
                object-fit: cover;
                
                @include respond-above(md) {
                    height: auto;
                }
            }
            
            figcaption {
                position: absolute;
                z-index: 1;
                
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                
                width: 100%;
                height: 100%;
                
                padding-top: 30px;
                
                padding: 30px 15px 0;
                
                
                @media (min-width: 768px) {
                    width: 768px;
                }
                @media (min-width: 992px) {
                    width: 992px;
                }
                @media (min-width: 1200px) {
                    width: 1200px;
                }
                
                @include respond-above(md) {
                    height: auto;
                    
                    bottom: 100px;
                    
                    padding: 0;
                }
                
                h2 {
                    display: inline-block;
                    
                    color: #fffffd;
                    font-family: "Raleway";
                    font-size: 1.1875rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    
                    max-width: calc(100% * 210 / (320 - 15 - 15));
                    
                    padding: 10px 24px;
                    margin-bottom: 5px;
                    
                    margin-top: 10px;
                    
                    border-radius: 2px;
                    background-color: #0085cf;
                    
                    @include respond-above(md) {
                        font-size: 2.5rem;
                        
                        padding: 0 24px;
                        
                        margin-top: 20px;
                        
                        max-width: 100%;
                        
                        height: 80px;
                        line-height: 80px;
                    }
                    
                    sup {
                        top: -0.5rem;
                    }
                    
                    &:before, &:after {
                        display: none;
                    }
                }
                .date, .description {
                    display: inline-block;
                    
                    
                    padding: 10px 25px;
                    
                    
                    border-radius: 2px;
                    background-color: #002663;
                    border-left: 5px solid #bef600;
                    
                    @include respond-above(md) {
                        height: 50px;
                        line-height: 50px;
                        
                        padding: 0 25px;
                    }
                }
                
                .date {
                    color: #fffffd;
                    font-family: "Raleway";
                    font-size: 0.75rem;
                    font-weight: 700;
                    
                    margin-bottom: 5px;
                    
                    @include respond-above(md) {
                        font-size: 1.25rem;
                    }
                }
                .description {
                    color: #fffffd;
                    font-family: Raleway;
                    font-size: 0.75rem;
                    
                    font-weight: 500;
                    
                    @include respond-above(md) {
                        font-size: 1rem;
                    }
                    
                }
            }
        }
    }
}
.home.titleWrap {
    position: relative;
    
    h1 {
        padding-left: 23px;
        
    }
    
    &:after {
        content: 'AGENDA';
        
        color: rgba(47, 47, 47, 0.05);
        font-family: "Raleway";
        font-size: 11vw;
        font-weight: 900;
        text-transform: uppercase;
        
        line-height: .75;
        
        position: absolute;
        left: 200px;
        top: -20px;
        
        display: none;
        
        @include respond-above(md) {
            display: block;
        }
    }

    &.titleActu:after {
        content: 'ACTUALITÉS';
    }
}
.quickaccess {
    margin-bottom: 130px;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    a {
        figure {
            position: relative;
            
            border-radius: 2px;
            overflow: hidden;
            height: 135px;
            
            /*img {
                width: 100%;
                height: auto;
            }*/
            
            figcaption {
                position: absolute;
                top: 0;
                left: 0;
                
                width: 100%;
                height: 100%;
                
                background-color: rgba(0, 0, 0, 0.4);
                color: #ffffff;
                font-family: Raleway;
                font-size: 1rem;
                font-weight: 700;
                text-transform: uppercase;
                
                text-align: center;
                
                transition: all .15s linear;
                
                span {
                    display: block;
                    width: 100%;
                    max-width: 170px;
                    
                    position: absolute;
                    
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    
                    &:after, &:before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        
                        width: 20px;
                        height: 1px;
                        background-color: #fffffd;
                        
                        transform: translateX(-50%);
                        
                        transition: all .15s linear;
                    }
                    
                    &:after {
                        bottom: -10px;
                    }
                    
                    &:before {
                        top: -10px;
                    }
                }
            }
        }
        
        &:hover {
            figure {
                figcaption {
                    transition: all .15s linear;
                    span {
                        &:after, &:before {
                            width: 30px;
                            transition: all .15s linear;
                        }
                    }
                }
            }
        }
    }   
}