html, body{
	font-family: "Raleway";
	font-size: 16px;
}

.row:before,
.row:after{
    width: 0;
}

.select2 {
    height: 45px;
    line-height: 45px;
    
    * {
        outline: 0;
    }

    .selection {
        height: inherit;
        line-height: inherit;
        .select2-selection {
            height: inherit;
            line-height: inherit;
            
            border: 1px solid rgba(0, 0, 0, 0.1);
            
            color: #2f2f2f;
            font-family: Raleway;
            font-size: 0.9375rem;
            font-weight: 500;

            .select2-selection__rendered, .select2-selection__arrow {
                height: inherit;
                line-height: inherit;
            }

            .select2-selection__rendered {
                padding-left: 15px;
            }
        }
    }

    .select2-selection__arrow {
        b {
            display: none;
        }

        &:after {
            content: '\eab2';
            font-family: icofont;
        }
    }
}

.select2-container {
    .select2-dropdown {
        border: 1px solid rgba(0, 0, 0, 0.1);
        
        .select2-search {
            outline: 0;
        }
        
        .select2-results {
            
        }
    }
}