.filterWrap {
    margin-bottom: 50px;

    form {
        min-height: 100px;

        border-radius: 2px;
        background-color: #f2f2f2;

        padding-left: 15px;
        padding-right: 15px;

        padding-top: 30px;

        @include respond-above(sm) {
            padding-left: 87px;
            padding-top: 0;
        }

        .datePickerWrap, .selectWrap, .submitWrap, .resetWrap {
            display: inline-block;
            line-height: 1;

            margin-bottom: 30px;

            width: 100%;

            @include respond-above(sm) {
                width: initial;
                line-height: 100px;
                margin-bottom: 0;
            }
        }

        .datePickerWrap {
            .inputWrap {
                height: 100px;
            }

            label {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 0.9375rem;
                font-weight: 500;

                width: 20px;
            }

            span.inputWrap {
                position: relative;

                input {
                    width: calc(100% - 35px);

                    height: 45px;
                    line-height: 45px;
                    border-radius: 2px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    background-color: #ffffff;
                    outline: 0;

                    margin-left: 10px;

                    padding-left: 15px;

                    color: rgba(47, 47, 47, 0.7);
                    font-family: Raleway;
                    font-size: 0.9375rem;
                    font-weight: 500;

                    @include respond-above(sm) {
                        margin: 0 10px;
                        width: 155px;
                    }
                }

                i {
                    position: absolute;

                    right: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .selectWrap {

            .select2-container--default {
                width: 100%;

                @include respond-above(sm) {
                    margin: 0 15px;
                    width: 245px;
                }

                .select2-selection--single {
                    .select2-selection__rendered {
                        color: rgba(47, 47, 47, 0.7);
                    }
                }
            }
        }

        .submitWrap {
            input {
                width: 100%;
                height: 45px;
                border-radius: 2px;
                background-color: #0085cf;
                line-height: 1;
                border: 0;
                outline: 0;

                color: #ffffff;
                font-family: "Raleway";
                font-size: 0.875rem;
                font-weight: 700;
                text-transform: uppercase;

                transition: all .15s linear;

                @include respond-above(sm) {
                    width: 150px;
                    margin: 0 15px;
                }

                &:hover {
                    background-color: lighten(#0085cf, 10%);

                    transition: all .15s linear;
                }
            }
        }

        .resetWrap {
            input {
                color: #2f2f2f;
                font-family: Raleway;
                font-size: 0.8125rem;
                font-weight: 500;
                text-decoration: underline;
                outline: 0;

                display: inline-block;

                border: none;
    			background: none;

                @include respond-above(sm) {
                    margin: 0 15px;
                }
                &:focus{
                	outline: none;
                }
            }
        }
    }
}

/******************************************************************/

/***********************  LISTE ARTICLE ***************************/

/******************************************************************/

.actusListe{
    article{
        a{
            .datePublication{
                color: #2f2f2f;
                font-family: "Raleway";
                font-weight: 700;
                font-size: .8125rem;

                line-height: 1;

                // height: 65px;
                padding-top: 20px;
                padding-bottom: 20px;

                position: relative;

                &:after {
                    content: '';

                    position: absolute;

                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);

                    width: 25px;
                    height: 1px;
                    background-color: #000000;
                        opacity: 0.2;
                }
            }
            .titleWrap {
                &:after{
                    display:none;
                }
            }

        }
    }
}

/******************************************************************/

/************************  LISTE EVENT ****************************/

/******************************************************************/

.evenementsListe {
    margin-bottom: 30px;

    display:flex;
    flex-wrap:wrap;

    article {
        // overflow: hidden;

        padding-bottom: 30px;
        height: 100%;

        display: flex;

        a {
            display: block;
            width: 100%;

            transition: all .15s linear;

            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.1);

            &:hover {
                text-decoration: none;
                background: #F2F2F2;

                transition: all .15s linear;
            }

            figure {
                position: relative;

                margin-bottom: 0px;

                height: 170px;

                /*img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }*/

                figcaption {
                    display: inline-block;
                    height: 40px;
                    line-height: 40px;
                    background-color: #bed600;

                    color: #2f2f2f;
                    font-family: "Raleway";
                    font-size: 0.8125rem;
                    font-weight: 700;
                    text-transform: uppercase;

                    padding: 0 16px;

                    position: absolute;
                    bottom: 0;
                    left: 50%;

                    transform: translateX(-50%);
                }
            }

            .contentWrap {
                text-align: center;

                .dateWrap {
                    color: #2f2f2f;
                    font-family: "Raleway";
                    font-weight: 400;
                    text-transform: uppercase;
                    font-weight: 700;

                    line-height: 1;

                    height: 90px;
                    padding-top: 20px;

                    position: relative;

                    &:after {
                        content: '';

                        position: absolute;

                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);

                        width: 25px;
                        height: 1px;
                        background-color: #000000;
                        opacity: 0.2;
                    }

                    .date {
                        font-size: 2.25rem;
                        line-height: .7;
                    }

                    .month {
                        font-size: 1.25rem;
                        line-height: 1.7;
                    }

                    .multipleDate {
                        .firstDate, .secondDate {
                            display: inline-block;

                            margin: 0 13px;
                        }

                        .firstDate {
                            position: relative;

                            &:after {
                                content: '-';

                                position: absolute;
                                top: 50%;
                                right: -50%;

                                transform: translateY(-50%) translateX(-50%);
                            }
                        }
                    }
                }

                .titleWrap {
                    // padding: 0 40px 0;

                    // height: 72px;
                    // line-height: 72px;

                    position: relative;

                    padding: 15px 30px;

                    h2 {
                        display: inline-block;
                        vertical-align: middle;
                        line-height: normal;

                        color: #2f2f2f;
                        font-family: Raleway;
                        font-size: 1.0625rem;
                        font-weight: 500;
                        line-height: 1.3;
                        text-transform: none;

                        margin: 0;


                        &:after, &:before {
                            display: none;
                        }
                    }

                    &:after {
                        content: '';

                        position: absolute;

                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);

                        width: 25px;
                        height: 1px;
                        background-color: #000000;
                        opacity: 0.2;
                    }
                }

                .adresseWrap {
                    color: #2f2f2f;
                    font-family: "Raleway";
                    font-size: 0.8125rem;
                    font-weight: 600;

                    // padding: 20px 10px 0;

                    // height: 74px;

                    padding: 15px 10px;

                    strong {
                       font-weight: 700;
                    }
                }
            }
        }
    }
}

.pageOnglet{
    .loadMore{
        padding-bottom: 60px;
    }
}

.loadMore {
    text-align: center;
    button, input, a {
        display: inline-block;

        height: 45px;
        line-height: 45px;

        border-radius: 2px;
        border: 0;
        background-color: #002663;

        padding: 0 30px;

        color: #ffffff;
        font-family: "Raleway";
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        outline: 0;

        transition: all .15s linear;

        i {
            margin-right: 10px;

            transition: all .15s linear;
        }

        &:hover {
            background-color: darken(#002663, 10%);

            transition: all .15s linear;

            i {
                margin-right: 15px;

                transition: all .15s linear;
            }
        }
    }
    #formAgenda, #formActualite{
    	display: inline-block;
    	position: relative;
        width: 100%;
        @include respond-above(sm){
            width: auto;
        }
        input{
            width: 100%;
            white-space: normal;
            line-height: unset;
            @include respond-above(sm){
                line-height: 45px;
                white-space: pre;
            }
        }
    	.icofont-long-arrow-right{
    		position: absolute;
    		color: white;
    		left: 10px;
    		top:50%;
    		transform:translateY(-50%);
    	}
    	input:hover {
            padding-left: 40px;
            transition: all .15s linear;
        }
    }
}

/******************************************************************/

/***************************** CARTO ******************************/

/******************************************************************/
.mapFilter {
    margin-bottom: 30px;
    form.filterWrap {
        border-radius: 2px;
        background-color: #bed600;

        padding-left: 37px;
        padding-right: 37px;
        padding-top: 36px;
        padding-bottom: 12px;
        margin-bottom: 0;

        position: relative;

        &:after {
            content: '\ef29';
            font-family: icofont;
            color: rgba(47, 47, 47, 0.15);
            font-size: 4.375rem;

            position: absolute;
            top: -13px;
            left: -6px;
        }

        label.formLabel {
            color: #2f2f2f;
            font-family: "Raleway";
            font-size: 1.25rem;
            font-weight: 900;
            text-transform: uppercase;
            display: block;

            margin-bottom: 25px;
        }

        label.customCheckbox{
            display: block;
        }

        input[type=submit] {
            display: block;
            margin: 0 auto;
            width: 175px;
            height: 45px;
            border-radius: 2px;
            background-color: #0085cf;
            outline: 0;

            border: none;
            color: #fffffd;
            font-family: "Raleway";
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;

            margin-bottom: 10px;
        }

        input[type=reset] {
            display: block;
            margin: 0 auto;
            height: 40px;
            width: 175px;

            background: rgba(42, 42, 42, 0);
            border-radius: 2px;
            border: none;
            outline: 0;

            color: #2f2f2f;
            font-family: Raleway;
            font-size: 0.8125rem;
            font-weight: 500;
            text-decoration: underline;

            transition: all .15s linear;

            &:hover {
                background: rgba(42, 42, 42, 0.1);
                transition: all .15s linear;
            }
        }
    }

}

.mapContent {
    #map_carte_interactive {
        width: 100%;
        height: 510px;

        margin-bottom: 30px;
    }

    article {
        margin-bottom: 30px;

        .annuaire-detail {
            display: block;

            min-height: 150px;
            border-radius: 2px;
            border-radius: 0 0 2px 2px;
            border-bottom: 3px solid rgba(0, 134, 207, 0);
            background-color: #f2f2f2;
            text-decoration: none;

            padding-top: 58px;
            padding-left: 40px;
            padding-right: 40px;
            padding-bottom: 38px;

            position: relative;

            transition: all .15s linear;

            .categorie {
                position: absolute;
                left: 0;
                top: 0;

                height: 35px;
                line-height: 35px;

                border-radius: 2px 0 0;
                background-color: #2f2f2f;

                padding: 0 18px;

                color: #ffffff;
                font-family: "Raleway";
                font-size: 0.75rem;
                font-weight: 700;
                text-transform: uppercase;

                transition: all .15s linear;
            }

            h2 {
                color: #2f2f2f;
                font-family: "Raleway";
                font-size: 1.3125rem;
                font-weight: 500;

                margin-top: 0;
                margin-bottom: 20px;

                &:before, &:after {
                    display: none;
                }
            }

            p {
                margin-bottom: 0;
                position: relative;

                padding-left: 25px;

                a{
                    color: #2f2f2f;
                }

                i {
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }

            &:hover {
                border-bottom: 3px solid #0086CF!important;

                transition: all .15s linear;

                .categorie {
                    background-color: #0085cf!important;

                    transition: all .15s linear;
                }
            }
        }
    }
}

/******************************************************************/

/************************** PAGE ONGLET ***************************/

/******************************************************************/

.banniere-onglet{
  height: 205px;
  margin-bottom: 50px;
  /*img{
    width: 100%;
  }*/
}

.rubriquesWrap {
    .rubriqueSingle {
        a {
            display: block;
            height: 110px;
            border-radius: 2px;
            background-color: #f2f2f2;
            border-radius: 0 0 2px 2px;
            border-bottom: 5px solid #002663;
            margin-bottom: 30px;
            padding: 0 10px;

            text-align: center;
            line-height: 110px;

            color: #2f2f2f;
            font-family: "Raleway";
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;

            transition: all .15s linear;

            span {
                display: inline-block;
                vertical-align: middle;
                line-height: normal;

                word-break: break-word;
                hyphens: auto;
                max-width: 100%;
            }

            &:hover {
                background-color: darken(#f2f2f2, 10%);
                border-bottom: 3px solid #002663;

                transition: all .15s linear;
            }
        }

        &.facebookLink {
            a {
                background-color: #4a67ad;
                border: none;

                line-height: 1;

                color: #ffffff;
                font-family: "Raleway";
                font-size: 1rem;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: none;

                padding-top: 30px;

                transition: all .15s linear;

                i {
                    display: block;
                    font-size: 1.5625rem;

                    margin-bottom: 14px;
                }

                &:hover {
                    box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.03);

                    transition: all .15s linear;
                }
            }
        }
    }
}

/******************************************************************/

/**************************** CONTACT *****************************/

/******************************************************************/

.wpcf7{
    margin-bottom: 30px;
    label {
        display: block;
        color: #2f2f2f;
        font-family: Raleway;
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 8px;
    }

    input[type=text],
    input[type=email] {
        width: 100%;
        height: 45px;
        border-radius: 2px 0 0 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        outline: 0;
        margin-bottom: 20px;

        padding-left: 15px;
    }

    textarea {
        width: 100%;
        border-radius: 2px 0 0 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        outline: 0;
        margin-bottom: 20px;

        padding: 15px;
    }

    input[type=submit] {
        width: 150px;
        height: 45px;
        border-radius: 2px;
        background-color: #0085cf;
        border: 0;
        outline: 0;

        color: #fffffd;
        font-family: "Raleway";
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: uppercase;

        transition: all .15s linear;

        &:hover {
            background-color: lighten(#0085cf, 10%);

            transition: all .15s linear;
        }
    }
}

/******************************************************************/

/**************************** SITEMAP *****************************/

/******************************************************************/
#wp-realtime-sitemap-pages, #wp-realtime-sitemap-categories, #wp-realtime-sitemap-agenda, #wp-realtime-sitemap-annuaires{
	ul{
		padding-left: 20px;
            >li{
            padding:10px 0px;
            /*list-style-type: disc;*/
            a{
                color: #2f2f2f;
                text-transform: uppercase;
            }
            .children{
                li{
                    a{
                        text-transform: capitalize;
                    }
                }
            }
        }
	}
}

/******************************************************************/

/**************************** SEARCH *****************************/

/******************************************************************/
.pagination-search{
    text-align: center;
    .page-numbers{
        color: #2f2f2f;
        padding: 0px 10px;
    }
    .current{
        font-size:1.125rem;
        font-weight: 600;
    }
}

.pdfprnt-buttons-search{
    display: none;
}

