@media print {
    .sidebar {
        display: none !important;
    }
    
    aside {
        display: none;
    }
    
    header {
        display: none;
    }
    
    .contentBloc {
        .blocImage {
            .col-md-6 {
                width: 50%;
                padding: 0 15px;
                
                float: left;
                
                figure {
                    position: initial;
                    overflow: initial;

                    figcaption {
                        opacity: 1;
                        position: initial;
                    }
                }
            }
            
            .col-md-4 {
                width: calc(100% / 3);
                padding: 0 15px;
                
                float: left;
                
                figure {
                    position: initial;
                    overflow: initial;

                    figcaption {
                        opacity: 1;
                        position: initial;
                    }
                }
            }
            
            figure {
                position: initial !important;
                overflow: initial !important;
                
                figcaption {
                    opacity: 1 !important;
                    position: initial !important;
                }
            }
        }
    }
    
    footer {
        display: none;
    }
}