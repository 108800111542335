$menu-animation-duration: 400ms;
$menu-animation-timing: ease-out;

nav {
    height: 130px;
    line-height: 130px;
    
    text-align: center;
    
    background-color: #ffffff;
    border-top: 3px solid #449bd0;
    

    
    .navWrapper {
        margin: 0;
        padding: 0;
        
        z-index: 15;
        
        background: #ffffff;
    }
    
    ul {
        margin: 0;
        padding: 0;
        
        position: relative;
        
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        
        width: 100%;
        
        > li {
            
            list-style: none;
            text-align: left;
            
            @include respond-above(md) {
                display: inline-block;
            }
            
            > a {
                display: block;
                
                vertical-align: middle;
                line-height: normal;
                
                height: 30px;
                line-height: 30px;
                
                padding-left: 30px;
                
                color: #2f2f2f;
                font-family: "Raleway";
                font-size: 0.8125rem;
                font-weight: 600;
                text-transform: uppercase;
                
                position: relative;
                
                @include respond-above(md) {
                    display: inline-block;
                    
                    text-align: center;
                    margin: 0 25px;
                    
                    padding-left: 0;
                }
                
                > span {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                    
                    width: 100%;
                    
                    br {
                        display: none;
                        
                        @include respond-above(md) {
                            display: block;
                            line-height: 0px;
                        }
                    }
                    
                }
                
                &:after {
                    display: none;
                    
                    position: absolute;

                    bottom: -14px;
                    left: 0;

                    content: '';

                    height: 4px;
                    width: 100%;
                    
                    transition: all .15s linear;
                    
                    @include respond-above(md) {
                        display: block;
                    }
                }
                
                &:hover {
                    text-decoration: none;
                    color: inherit;
                    
                    &:after {
                        bottom: -50px;
                        height: 36px;
                        transition: all .15s linear;
                    }
                }
            }
            
            &.menuLink {
                position: relative;
                
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 14px;

                &:first-of-type {
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                }

                &:last-of-type {
                    border-bottom: 0;
                }
                
                display: none;
                
                @include respond-above(md) {
                    border: none;
                    
                    display: inline-block;
                }
                
                & > a {
                    height: 50px;
                    line-height: 50px;
                    
                    @include respond-above(md) {
                        height: 30px;
                        line-height: 30px;
                    }
                
                    & > span {
                       
                    }
                }
                
                & > ul {
                    display: none;
                    
                    & > li {
                        & > a {
                            & > span {
                                
                            }
                        }
                        &.subMenuLink{
                            position: relative;
                        }
                    }
                }
                
                &.active {
                    a:after {
                        bottom: -50px;
                        height: 36px;
                        transition: all .15s linear;
                    }
                    
                    & > ul {
                        display: block;

                        left: 25px;

                        width: 250px;

                        z-index: 15;

                        background-color: #fff;
                        
                        @include respond-above(md) {
                            position: absolute;
                            top: 80px;
                        }

                        & > li {
                            display: block;
                            text-align: left;

                            vertical-align: middle;
                            line-height: normal;

                            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                            &:first-of-type {
                                border-top: 1px solid rgba(0, 0, 0, 0.1);
                            }

                            &:last-of-type {
                                border-bottom: 0;
                            }

                            & > a {
                                display: block;

                                width: 100%;
                                height: 50px;
                                line-height: 50px;

                                text-align: left;

                                padding-left: 25px;
                                margin: 0;

                                transition: all .15s linear;

                                i {
                                    margin-right: 10px;

                                    font-size: 0.875rem;
                                }
                            }
                        }
                    }
                }
            }
            
            &.logo {
                position: relative;
                a {
                    height: 100%;
                    padding: 0;
                    img {
                        margin: 15px auto;
                        display: block;
                        
                        height: 100px;

                        @include respond-above(md) {
                            margin: 15px 0;
                        }
                    }
                }
            }

            &.logo.is-open ~ .menuLink {
                display: block;
                    
                @include respond-above(md) {
                    display: inline-block;
                }
            }
            
            &.search {
                display: none;
                
                @include respond-above(md) {
                    display: inline-block;
                    
                    text-align: center;
                }

                ul {
                    display: none;
                }
            }
            
            &.search.active {
                ul {
                    display: block;
                    
                    position: absolute;
                    left: 0;
                    top: 130px;
                    
                    margin: 0;
                    padding: 0;
                    
                    width: 100%;
                    height: 150px;
                    border-radius: 0 0 2px 2px;
                    background-color: #f2f2f2;
                    
                    padding: 30px 0;
                    
                    z-index: 2;
                    
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                    
                    @include respond-above(md) {
                        padding: 50px 0;
                    }
                    
                    li {
                        display: inline-block;
                        vertical-align: middle;
                        line-height: normal;
                        
                        @include respond-below(xs) {
                            width: 100%;
                        }
                        
                        form {
                            @include clearfix;
                            
                            input[type='text'], input[type='submit'] {
                                width: 100%;
                            }
                            
                            @include respond-above(md) {
                                input[type='text'], input[type='submit'] {
                                    float: left;
                                }
                            }
                            
                            input[type='text'] {
                                @include respond-above(md) {
                                    width: calc(100% - 150px);
                                }

                                height: 45px;
                                border-radius: 2px 0 0 2px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                background-color: #ffffff;
                                
                                padding: 0 20px;
                                
                                outline: 0;
                                
                                &::-webkit-input-placeholder {
                                    opacity: 0.7;
                                    color: #2f2f2f;
                                    font-family: "Raleway";
                                    font-size: 0.9375rem;
                                    font-weight: 400;
                                    font-style: italic;
                                }

                                &::-moz-placeholder {
                                    opacity: 0.7;
                                    color: #2f2f2f;
                                    font-family: "Raleway";
                                    font-size: 0.9375rem;
                                    font-weight: 400;
                                    font-style: italic;
                                }

                                &:-ms-input-placeholder {
                                    opacity: 0.7;
                                    color: #2f2f2f;
                                    font-family: "Raleway";
                                    font-size: 0.9375rem;
                                    font-weight: 400;
                                    font-style: italic;
                                }

                                &:-moz-placeholder {
                                    opacity: 0.7;
                                    color: #2f2f2f;
                                    font-family: "Raleway";
                                    font-size: 0.9375rem;
                                    font-weight: 400;
                                    font-style: italic;
                                }
                            }

                            input[type='submit'] {
                                @include respond-above(md) {
                                    width: 150px;
                                }
                                
                                height: 45px;
                                border-radius: 0 2px 2px 0;
                                background-color: #0085cf;
                                
                                line-height: 1;
                                color: white;
                                
                                border: 0;
                                
                                outline: 0;
                            }
                        }
                    }
                }

                ul.searchForm{
                    i{
                        position: absolute;
                        top: 10px;
                        right: 15px;
                        @include respond-above(md){
                            top: 20px;
                            right: 25px;
                        }
                    }
                }
            }
            
            /*&:before {
                display: none;
            }*/

            &.lien-facebook{
                a{
                    padding-left: 50px!important;
                }
                &:before{
                    position: absolute;
                    content: "\f082";
                    font-size: 0.875rem;
                    font-family: "Font Awesome 5 Brands";
                    top:50%;
                    left: 25px;
                    transform:translateY(-50%);
                }
                &:hover:before{
                    left: 35px;
                     transition: all .15s linear;   
                }
            }
        }
    }
}

.burger-click-region {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateX(-50%);

    border:none;
    outline: none;
    
    width: 25px;
    height: 16px;

    background-color: #fff;
    
    padding: 0;
    
    cursor: pointer;
    
    @include respond-above(md) {
        display: none;
    }
}

.burger-menu-piece {
    display: block;
    position: absolute;

    width: 25px;
    height: 2px;

    border-radius: 1px;
    background-color: #2f2f2f;

    transform-origin: 50% 50%;
    transition: transform $menu-animation-duration $menu-animation-timing;

    &:nth-child(1) {
        top: 0;
    }

    &:nth-child(2) {
        top: 7px;
        opacity: 1;
        transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
    }

    &:nth-child(3) {
        top: 14px;
    }

    .active & {

        &:nth-child(1) {
            animation: burger-open-top $menu-animation-duration $menu-animation-timing forwards;
        }

        &:nth-child(2) {
            opacity: 0;
            transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
        }

        &:nth-child(3) {
            animation: burger-open-bot $menu-animation-duration $menu-animation-timing forwards;
        }
    }

    .closing & {
        &:nth-child(1) {
            animation: burger-close-top $menu-animation-duration $menu-animation-timing forwards;
        }

        &:nth-child(3) {
            animation: burger-close-bot $menu-animation-duration $menu-animation-timing forwards;
        }
    }
}

.menu_compacted .bouton-overflow{
    display: none;
}


@keyframes burger-open-top {
    50% {
        transform: translate3d(0, 7px, 0);
    }

    100% {
        transform: translate3d(0, 7px, 0) rotate(45deg);
    }
}

@keyframes burger-open-bot {
    50% {
        transform: translate3d(0, -7px, 0);
    }

    100% {
        transform: translate3d(0, -7px, 0) rotate(-45deg);
    }
}

@keyframes burger-close-top {
    0% {
        transform: translate3d(0, 7px, 0) rotate(45deg);
    }

    50% {
        transform: translate3d(0, 7px, 0) rotate(0deg);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes burger-close-bot {
    0% {
        transform: translate3d(0, -7px, 0) rotate(-45deg);
    }

    50% {
        transform: translate3d(0, -7px, 0) rotate(0deg);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@media all and (min-width:992px) {
  .menu_compacted {position: relative;}
  .menu_compacted .bouton-overflow { display: inline-block; position: absolute; height: 130px; right: 0; padding: 0 5px; border: 0; outline: none; background-color:$c-darkblue; color: #fff; cursor: pointer; z-index:20;}
  .menu_compacted .bouton-overflow:hover {opacity: 0.7;}
  .menu_compacted .bouton-overflow::after { content: attr(count); position: absolute; width: 26px; height: 26px; left: -14px; top: 5px; text-align: center; background-color: $c-darkblue; color: #fff; font-size: 14px; line-height: 24px; border-radius: 50%; border: 2px solid #fff; font-weight: bold;}
  .menu_compacted .bouton-overflow:hover::after {transform: scale(1.075);}
  .menu_compacted .hamburger {position: relative;width: 20px;height: 2px;background: #fff;margin: auto;}
  .menu_compacted .hamburger::before,.menu_compacted .hamburger::after{ content: ''; position: absolute; left: 0; width: 20px; height: 2px; background: #fff;}
  .menu_compacted .hamburger:before{top: -6px;}
  .menu_compacted .hamburger:after{bottom: -6px;}

  .menu_compacted .hidden-links { position: absolute; right: 0px; top: 130px; z-index:20; background-color: #fff; width:260px; }
  .menu_compacted .hidden-links>li { display: block; position: relative; border-bottom: 1px solid rgba(0,0,0,.1); padding-bottom: 14px; }
  .menu_compacted .hidden-links>li>a {height: 50px; line-height: 50px;}
  .menu_compacted .hidden-links>li>a:after{display: none;}
  .menu_compacted .hidden-links>li>a>span {display: inline-block;vertical-align: middle;line-height: normal;width: 100%;}

  .hiddens { visibility: hidden; }

  .menu_compacted:not(.home) .navWrapper.menu-principal{border-bottom: 1px solid rgba(0,0,0,0.1); margin-bottom: 50px;}
}
